import { ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
import { HelmetProvider } from 'react-helmet-async';
import './index.css'

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container); // 

root.render(
  // <StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <ColorModeScript />
          <App />
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </HelmetProvider>
  // </StrictMode>
);
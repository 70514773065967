import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { connectorApi } from '../api/requestFactory';
import useRequest from '../api/useRequest';
import { UserContext } from './UserContextComponent';
import LoadingBox from '../components/common/LoadingBox';
import useToastMessage from '../hooks/useToastMessage';
import { useNavigate } from 'react-router-dom';

export const AccountContext = createContext();

export const AccountContextComponent = props => {
  const { children } = props;
  const { userData } = useContext(UserContext);
  const { accountId } = useParams();
  const { requestMaker } = useRequest();
  const [accountData, setAccountData] = useState();
  const [currentProfile, setCurrentProfile] = useState(undefined);
  const [isFetching, setIsFetching] = useState(false);
  const { showErrorToast } = useToastMessage();
  const navigate = useNavigate();

  // const refreshAccountData = async () => {
  //   setIsFetching(true);
  //   try {
  //     const response = await requestMaker(
  //       connectorApi.listConnectors(accountId)
  //     );
  //     const connections = response.data.items;
  //     const newAccountData = { connections: connections };
  //     setAccountData(newAccountData);
  //     setIsFetching(false);
  //   } catch (err) {
  //     setIsFetching(false);
  //     console.error(err);
  //     showErrorToast(err);
  //   }
  // };

  // Uncomment if you want to refresh account data when user data changes
  // https://stackoverflow.com/a/56443045
  // useEffect(() => {
  //   if (!accountData && !isFetching) {
  //     refreshAccountData();
  //   }
  // }, [accountData, userData]); // eslint-disable-line react-hooks/exhaustive-deps

  // ran if 'accountId' changes, but not on initial render
  // useDidMountEffect(() => {
  //   if (accountId) {
  //     refreshAccountData();
  //   }
  // }, [accountId]);

  const refreshAccountData = useCallback(async () => {
    setIsFetching(true);
    try {
      const response = await requestMaker(
        connectorApi.listConnectors(accountId)
      );
      const connections = response.data.items;
      const newAccountData = { connections: connections };
      setAccountData(newAccountData);
      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      console.error(err);
      showErrorToast(err);
    }
  }, [accountId, requestMaker, showErrorToast]);

  useEffect(() => {
    if (accountId) {
      refreshAccountData();
    }
  }, [accountId, refreshAccountData]);

  useEffect(() => {
    if (!userData) return;

    const profiles = userData.profiles;
    const newCurrentProfile = profiles.find(
      profile => profile.account.id === accountId
    );
    // If the current accountId is not one of the accounts the user is in, redirect to first account the user is in
    if (!newCurrentProfile) {
      navigate(`/dashboard/${profiles[0].account.id}/connections`);
      return;
    }
    setCurrentProfile(newCurrentProfile);
  }, [accountId, userData, navigate]);

  if (!accountId) {
    return (
      <AccountContext.Provider
        value={{ accountData, currentProfile, refreshAccountData }}
      >
        {children}
      </AccountContext.Provider>
    );
  }

  if (!accountData) {
    return <LoadingBox />;
  }

  return (
    <AccountContext.Provider
      value={{
        accountData,
        currentProfile: currentProfile,
        refreshAccountData,
        isFetching,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

import React, { useEffect } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { useRoutes } from 'react-router-dom';
import routes from './routes';
import { Helmet } from 'react-helmet-async';
import theme from './theme';
import FullStory from 'react-fullstory';

const ORG_ID = '';

function App() {
  const content = useRoutes(routes);
  return (
    <>
      {/* <FullStory org={ORG_ID} namespace="FS" host="fullstory.com" /> */}
      <Helmet>
        <title>AlignICP</title>
      </Helmet>
      <ChakraProvider theme={theme}>{content}</ChakraProvider>
    </>
  );
}

export default App;

import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  fonts: {
    heading: 'Whitney, sans-serif',
    body: 'Whitney, sans-serif',
  },
  semanticTokens: {
    colors: {
      error: 'red.500',
      primary: '#3182ce',
      blurple: '#5865F2',
      blurpleLight: '#7d87f5',
      textSecondary: 'gray.500',
      offWhite: '#F0F0F0',
    },
  },
  colors: {
    primary: {
      50: '#BEE3F8',
      100: '#BEE3F8',
      200: '#90CDF4',
      300: '#90CDF4',
      400: '#90CDF4',
      500: '#3182ce',
      600: '#3182ce',
      700: '#3182ce',
      800: '#3182ce',
      900: '#1A365D',
    },
  },
  styles: {
    global: ({ colorMode }) => ({
      body: {
        bg: colorMode === 'dark' ? 'gray.800' : 'gray.50',
      },
    }),
  },
  components: {
    // https://github.com/chakra-ui/chakra-ui/issues/2347#issuecomment-997215492
    Input: {
      variants: {
        outline: ({ colorMode }) => ({
          field: {
            background: colorMode === 'dark' ? 'rgba(0,0,0,0)' : 'white',
            _focus: {
              borderColor: 'black',
              boxShadow: 'none',
            },
          },
        }),
      },
    },
    Select: {
      baseStyle: ({ colorMode }) => ({
        field: {
          background: colorMode === 'dark' ? 'rgba(0,0,0,0)' : 'white',
        },
      }),
    },
    Link: {
      variants: {
        customLink: {
          color: 'blurple',
        },
      },
      defaultProps: {
        variant: 'customLink',
      },
    },
    Tabs: {
      variants: {
        line: {
          tab: {
            _selected: {
              color: 'blurple',
              borderBottomColor: 'blurple',
              boxShadow: 'none',
            },
          },
        },
      },
    },
    Button: {
      baseStyle: { _focus: { boxShadow: 'none' } },
      variants: {
        solid: ({ colorMode }) => ({
          bg: 'black',
          color: 'white',
          _hover: { bg: colorMode === 'dark' ? 'gray.500' : 'gray.500' },
          _active: { bg: colorMode === 'dark' ? 'gray.500' : 'gray.500' },
        }),
      },
    },
    // https://stackoverflow.com/questions/69577974/overriding-the-theme-css-of-box
    Accordion: {
      baseStyle: {
        button: {
          _focus: { boxShadow: 'none' },
        },
      },
    },
  },
});

export default theme;

import { Box, HStack, Image } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import AlignLogo from '../../assets/align-logo.jpg';

const AlignICPLabel = () => {
  const navigate = useNavigate();
  const { accountId } = useParams();

  return (
    <HStack px="3" py="2" spacing="3">
      <Box
        display="inline-flex"
        position="relative"
        onClick={() => {
          navigate(`/dashboard/${accountId}/connections`);
        }}
        _hover={{
          cursor: 'pointer',
        }}
      >
        <Image src={AlignLogo} w={'48px'}></Image>
      </Box>
    </HStack>
  );
};

export default AlignICPLabel;

import { Fragment, useContext, useEffect } from "react";
import { FullStoryAPI } from 'react-fullstory';
import { UserContext } from "../../context/UserContextComponent";

const FullStory = (props) => {
  const { children } = props
  const { userData } = useContext(UserContext);

  useEffect(() => {
    if (!userData) return
    FullStoryAPI('setUserVars', {
      email: userData?.email,
      displayName: `${userData?.first_name} ${userData?.last_name}`,
    });
  }, [userData]);

  return (
    <Fragment>
      {children}
    </Fragment>
  )
}

export default FullStory
import React, { Suspense, lazy } from 'react';
import { ProtectedRoute } from './auth/ProtectedRoute';
import { UserContextComponent } from './context/UserContextComponent';
import { DashboardLayout } from './layout/DashboardLayout';
import { Outlet } from 'react-router-dom';

// eslint-disable-next-line react/display-name
const Loadable = Component => props =>
  (
    <Suspense fallback={<></>}>
      <Component {...props} />
    </Suspense>
  );

// Connection Pages
const Connections = Loadable(
  lazy(() => import('./pages/connections/Connections'))
);
const Connection = Loadable(
  lazy(() => import('./pages/connections/Connection'))
);

// Verify Email
const VerifyEmail = Loadable(lazy(() => import('./pages/VerifyEmail')));

// Create Account
const CreateAccount = Loadable(lazy(() => import('./pages/CreateAccount')));

const DefaultBotNavigate = () => {
  window.location.href = `/dashboard`;
};

const withUserContext = (Component, props) => {
  return class extends React.Component {
    render() {
      return (
        <>
          {props !== undefined ? (
            <UserContextComponent>
              <Component {...props} />
            </UserContextComponent>
          ) : (
            <UserContextComponent>
              <Component />
            </UserContextComponent>
          )}
        </>
      );
    }
  };
};

const EmptyLayout = children => {
  return (
    <>
      <Outlet />
    </>
  );
};

const routes = [
  {
    path: 'dashboard',
    element: <ProtectedRoute component={withUserContext(EmptyLayout, {})} />,
    children: [
      {
        path: 'new',
        element: <CreateAccount />,
      },
      {
        path: 'verify-email',
        element: <VerifyEmail />,
      },
      {
        path: '',
        element: <p></p>,
      },
    ],
  },
  {
    path: 'dashboard/:accountId',
    element: (
      <ProtectedRoute
        component={withUserContext(DashboardLayout, {
          showSidebarBuilder: true,
        })}
      />
    ),
    children: [
      {
        path: 'connections',
        children: [
          {
            path: '',
            element: <Connections />,
          },
          {
            path: ':service',
            element: <Connection />,
          },
        ],
      },
      {
        path: '',
        element: <></>,
      },
    ],
  },
  {
    path: '404',
    element: <p>Not Found</p>,
  },
  {
    path: '*',
    element: <ProtectedRoute component={withUserContext(DefaultBotNavigate)} />,
  },
];

export default routes;

import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import LoadingBox from "../components/common/LoadingBox";

export const ProtectedRoute = ({
  component,
}) => {

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <LoadingBox />,
  });

  return <Component />;
};
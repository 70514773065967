export class GeneralError {
  constructor() {
    this.code = 100;
    this.message = 'Something went wrong!';
    this.type = 'GENERAL_ERROR';
  }
}

export class NetworkError {
  constructor() {
    this.code = 100;
    this.message = 'You are offline. Please reconnect to the internet';
    this.type = 'NETWORK_ERROR';
  }
}

export class BadRequest {
  constructor({ code = undefined, error }) {
    this.code = code || 400;
    this.message = error?.display_error || 'Request Failed!';
    if (error?.field_errors) {
      let fieldErrorsMessage = ''
      for (const [key, value] of Object.entries(error.field_errors)) {
        fieldErrorsMessage = `${fieldErrorsMessage} ${value.toString()}\n`
      }
      this.message = fieldErrorsMessage
    }
    this.internalErrorCode = error?.internal_error_code;
    this.type = 'BAD_REQUEST';
  }
}

export class NotFound {
  constructor({ code = undefined, error }) {
    this.code = code || 404;
    this.message = error?.display_error || 'Not Found!';
    this.type = 'NOT_FOUND';
  }
}

export class ServerError {
  constructor(code, message) {
    this.code = code || 500;
    this.message = message || 'Uh oh! A server error occured. Please retry or contact AlignICP support.';
    this.type = 'SERVER_ERROR';
  }
}
import {
  Box,
  Center,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';
import { ToggleButton } from '../components/common/ToggleButton';
import AlignICPLabel from '../components/common/AlignICPLabel';
import { ProfileButton } from '../components/account/ProfileButton';

export const Navbar = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Box as="nav" bg={'gray.700'}>
      <Box py={{ base: '3', lg: '4' }} px={'3'}>
        <Flex justify="space-between">
          <HStack spacing="4">
            <AlignICPLabel />
          </HStack>
          {isDesktop ? (
            <HStack spacing="4">
              <ProfileButton />
            </HStack>
          ) : (
            <Box w="10" fontSize="sm" onClick={onToggle} cursor="pointer">
              <Center h="100%">
                <ToggleButton
                  isOpen={isOpen}
                  aria-label="Open Menu"
                  onClick={onToggle}
                  color="white"
                />
              </Center>
              <Drawer
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                isFullHeight
              >
                <DrawerOverlay />
                <DrawerContent maxWidth="64" bg={'gray.700'}>
                  <Flex justify="flex-end" p="4">
                    <ProfileButton />
                  </Flex>
                </DrawerContent>
              </Drawer>
            </Box>
          )}
        </Flex>
      </Box>
    </Box>
  );
};

import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';

export const useToastMessage = () => {
  const toast = useToast();

  const showSuccessToast = useCallback(
    (title, message) => {
      toast({
        title: title,
        description: message,
        status: 'success',
        position: 'top',
        isClosable: true,
        duration: 2000,
      });
    },
    [toast]
  );

  const showErrorToast = useCallback(
    async err => {
      toast({
        title: 'Error',
        description: err.message,
        status: 'error',
        position: 'top',
        isClosable: true,
        duration: 2000,
      });
    },
    [toast]
  );

  return {
    showSuccessToast: showSuccessToast,
    showErrorToast: showErrorToast,
  };
};

export default useToastMessage;

import { Box, Flex, useColorModeValue as mode } from '@chakra-ui/react';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';
import { AccountContextComponent } from '../context/AccountContextComponent';
import FullStory from '../components/common/FullStory';
import { Navbar } from './Navbar';

const DynamicHelmet = () => {
  return (
    <Helmet>
      <title> AlignICP | Dashboard</title>
    </Helmet>
  );
};

export const DashboardLayout = props => {
  const { children } = props;
  const boxColor = mode('offWhite', 'gray.800');

  return (
    <AccountContextComponent>
      <FullStory>
        <DynamicHelmet />
        <Box height="100vh" overflow="hidden" position="relative">
          <Flex h="full" id="app-container">
            {/* {isDesktop && (
                  <DashboardSidebar showSidebarBuilder={showSidebarBuilder} />
                )} */}
            <Box bg={boxColor} flex="1" overflowX={'auto'}>
              <Navbar />
              {children || <Outlet />}
            </Box>
          </Flex>
        </Box>
      </FullStory>
    </AccountContextComponent>
  );
};

const API_VERSION = 'api/v1';

class UserApi {
  sync = () => ({ method: 'put', url: `${API_VERSION}/user-sync/` });
  get = () => ({ method: 'get', url: `${API_VERSION}/user/` });
}

class AccountApi {
  createAccount = data => ({
    method: 'post',
    url: `${API_VERSION}/account/`,
    data: data,
  });
  // getStripeUrl = (accountId, returnUrl) => ({ method: 'get', url: `${API_VERSION}/create-customer-portal-session/${accountId}/?returnUrl=${returnUrl}` });
  // update = (accountId, data) => ({ method: 'put', url: `${API_VERSION}/team/${accountId}/`, data: data });
}

class ConnectorApi {
  createConnector = (accountId, data) => ({
    method: 'post',
    url: `${API_VERSION}/connectors/${accountId}/`,
    data: data,
  });
  deleteConnector = (accountId, connectorId) => ({
    method: 'delete',
    url: `${API_VERSION}/connectors/${accountId}/${connectorId}/`,
  });
  listConnectors = accountId => ({
    method: 'get',
    url: `${API_VERSION}/connectors/${accountId}/`,
  });
}


export const accountApi = new AccountApi();
export const connectorApi = new ConnectorApi();
export const userApi = new UserApi();
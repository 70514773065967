import React, { useEffect, createContext, useState } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import LoadingBox from '../components/common/LoadingBox';
import useRequest from '../api/useRequest';
import { userApi } from '../api/requestFactory';
import { useNavigate } from 'react-router-dom';

export const UserContext = createContext();

export const UserContextComponent = ({ children }) => {
  const [userData, setUserData] = useState(undefined);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const { requestMaker } = useRequest();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const refreshUserData = async () => {
    try {
      setIsLoadingUser(true);
      let response = {};
      // ?doSync=true will force sync Auth0 user with database. Do this on login
      if (searchParams.get('doSync') === 'true') {
        response = await requestMaker(userApi.sync());
      } else {
        response = await requestMaker(userApi.get());
      }
      setUserData(response.data);
      setIsLoadingUser(false);
      window.$crisp.push(['set', 'user:email', [response.data.email]]);
      window.$crisp.push(['set', 'user:nickname', [response.data.email]]);
    } catch (error) {
      setIsLoadingUser(false);
      console.error(error);
    }
  };

  useEffect(() => {
    refreshUserData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!userData) return;
    // if (userData.email_verified === false) {
    //   navigate('/dashboard/verify-email');
    //   return;
    // }
    const profiles = userData.profiles;
    if (!profiles.length) {
      navigate('/dashboard/new');
      return;
    }
    const currentPath = window.location.pathname;
    if (currentPath === '/dashboard' || currentPath === '/dashboard/') {
      navigate(`/dashboard/${profiles[0].account.id}/connections`);
      return;
    }
  }, [userData, navigate]);

  if (isLoadingUser || !userData) {
    return <LoadingBox />;
  }

  return (
    <UserContext.Provider
      value={{
        userData,
        profiles: userData.profiles,
      }}
    >
      {children || <Outlet />}
    </UserContext.Provider>
  );
};

import {
  Avatar,
  Box,
  Center,
  Flex,
  HStack,
  Menu,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spinner,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useMenuButton,
  VStack,
} from '@chakra-ui/react';
import * as React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { HiSelector } from 'react-icons/hi';
import { UserContext } from '../../context/UserContextComponent';
import { AccountContext } from '../../context/AccountContextComponent';

export const ProfileButton = props => {
  const { user } = useAuth0();
  const { profiles, userData } = React.useContext(UserContext);
  const { currentProfile } = React.useContext(AccountContext);
  const { logout } = useAuth0();
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const ProfileList = () => {
    if (!profiles) {
      return (
        <Center>
          <Spinner size={'sm'} />
        </Center>
      );
    }

    return (
      <>
        {profiles?.length > 1 && (
          <>
            <MenuOptionGroup defaultValue={currentProfile.id}>
              {profiles?.map(profile => {
                return (
                  <MenuItemOption
                    iconSpacing={0}
                    value={profile.id}
                    key={profile.id}
                    fontWeight="semibold"
                    rounded="md"
                    onClick={() => {
                      // TODO I could just do navigate here, but it's not updating the defaultValue of the MenuOptionGroup
                      window.location = `/dashboard/${profile.account.id}/connections`;
                    }}
                  >
                    <HStack flex="1" spacing="3" pl={2}>
                      <Text>{profile.account.name}</Text>
                    </HStack>
                  </MenuItemOption>
                );
              })}
            </MenuOptionGroup>
            <MenuDivider />
          </>
        )}
      </>
    );
  };

  const formatRole = role => {
    if (role === 'OWNER') {
      return 'Account Owner';
    }
    if (role === 'ADMIN') {
      return 'Account Admin';
    }
    return role;
  };

  const ProfileMenuButton = () => {
    const buttonProps = useMenuButton();
    const { picture, name } = user;
    return (
      <Flex
        as="button"
        {...buttonProps}
        w="full"
        display="flex"
        alignItems="center"
        rounded="lg"
        bg={'gray.600'}
        px="3"
        py="2"
        fontSize="sm"
        userSelect="none"
        cursor="pointer"
        outline="0"
        transition="all 0.2s"
        _active={{ bg: 'gray.500' }}
        _focus={{ shadow: 'outline' }}
        border="1px solid"
        shadow={'box-shadow: 0px 1px 0px 0px #FFFFFF99'}
      >
        <HStack flex="1" spacing="3">
          <Avatar
            w="40px"
            h="40px"
            objectFit="cover"
            src={picture}
            name={name}
          />
          <VStack align={'self-start'}>
            <Text color={'white'} fontWeight="semibold">
              {userData.first_name}
            </Text>
            <Flex display="flex" flexDirection={isDesktop ? 'row' : 'column'}>
              {currentProfile ? (
                <>
                  <Text color={'gray.200'}>
                    {formatRole(currentProfile.role)} •
                  </Text>
                  <Text color={'gray.200'} px={'1'}>
                    {currentProfile.account.name}
                  </Text>
                </>
              ) : (
                <Text color={'gray.200'}>No Account</Text>
              )}
            </Flex>
          </VStack>
        </HStack>
        <Box fontSize="lg" color="gray.400">
          <HiSelector />
        </Box>
      </Flex>
    );
  };

  return (
    <Menu>
      <ProfileMenuButton />
      <MenuList
        shadow="lg"
        py="4"
        color={useColorModeValue('gray.600', 'gray.200')}
        px="3"
        maxH={'50vh'}
        overflow="auto"
      >
        {currentProfile && ProfileList()}
        <MenuItem
          _hover={{ bg: useColorModeValue('red.100', 'red.700') }}
          rounded="md"
          onClick={() =>
            logout({
              returnTo: window.location.origin,
            })
          }
        >
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

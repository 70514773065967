import { Box, Center, Spinner } from "@chakra-ui/react"

const LoadingBox = () => {
  return (
    <Box height="100vh">

      <Center height="100vh">
        <Spinner
          thickness='7px'
          speed='1s'
          // emptyColor='gray.200'
          color='red.800'
          size='xl'
        />
      </Center>
    </Box>

  )
}
export default LoadingBox